<template>
  <div id="item">
    <v-list-item @click="selectItem" tile flat class="px-1"
                 :style="{ minHeight:'42px','background-color': (item === pos.gastro.selectedOpenItem ? 'rgba(63,106,216,0.45)' : (itemIndex % 2 === 1 ? this.$vuetify.theme.dark ? '#303030' : '#ededed' : ''))}">
      <v-list-item-content class=" py-0">
        <v-list-item-title>
          <v-row class="ma-0 mx-0" no-gutters>
            <v-col cols="12" md="12" sm="12"
                   :style="{'font-size': '14px', 'text-decoration': ((item.isVoid === true || item.isVoid === 1) ? 'line-through': '')}">
              <!-- Name -->
              <span
                  :style="{fontWeight: 'bold', 'text-decoration': ((item.isVoid === true || item.isVoid === 1) ? 'line-through': '')}">
                {{ item.isWeighted?1:item.amount }}x
              </span> <span class="text-wrap">{{ itemName }}</span>
              <span v-if="showGFMin" class="text-wrap"><br>{{ $t('generic.lang_min') }} : {{
                item.min_redeem_cart_value|currency
              }}</span>
              <span class="text-wrap" v-if="this.depositGroup !== null"><br>{{
                this.depositGroup.name
              }}</span>
              <div class="error--text pt-1">{{ item.serialNo }}</div>
            </v-col>
          </v-row>
        </v-list-item-title>
      </v-list-item-content>
      <!-- ITEM PRICE-->
      <v-list-item-action class="ma-0 pa-0">
        <v-row class="ma-0 mx-0 pr-1" no-gutters>
          <v-col class="text-center" cols="12" md="12" sm="12">

        <span
            :style="{fontSize:'14px !important',fontFamily: 'Anton', float: 'right', 'text-decoration': ((item.isVoid === true || item.isVoid === 1) ? 'line-through': '')}">
                <template v-if="!item.isWeighted">
                    {{ itemSellPrice | currency }}
                  <span style="color: #3F6AD8;"></span>/
                </template>
                {{ itemStackSellPrice | currency }}

                <span v-if="item.discount > 0" style="color: #D92550;">({{ item.discount }}%)</span>
              </span>

          </v-col>
        </v-row>
      </v-list-item-action>
      <!-- ITEM FREETEXT-->
      <v-list-item-action class="ma-0 pa-0 ml-1"
                          style="background-color: #21D59B; margin-right: 2px !important;" @click.stop="toggleFreeText">
        <v-card elevation="0" class="mx-auto pa-0 py-2" tile color="transparent">
          <v-row no-gutters>
            <v-col cols="12" align="center" class="text-center white--text">
              <font-awesome-icon :icon="['fal','comment-alt-lines']"/>
            </v-col>
          </v-row>
        </v-card>
      </v-list-item-action>
      <!-- ITEM COURSE-->
      <v-list-item-action v-if="item.course" class="ma-0 pa-0 ml-1"
                          style="background-color: #FF6969;margin-right: 2px !important;" @click="coursesDialog=true">
        <v-card elevation="0" class="mx-auto pa-0 py-2" tile color="transparent">
          <v-row no-gutters>
            <v-col cols="12" align="center" class="text-center white--text">
              {{ item.course.position }}
            </v-col>
          </v-row>
        </v-card>
      </v-list-item-action>
      <!-- ITEM EXTRAS-->
      <v-list-item-action v-if="itemHasExtras" class="ma-0 pa-0 ml-1"
                          style="background-color: #F99600;margin-right: 2px !important;"
                          @click.stop="openEditExtrasDialog">
        <v-card elevation="0" class="mx-auto pa-0 py-2" tile color="transparent">
          <v-row no-gutters>
            <v-col cols="12" align="center" class="white--text">
              <font-awesome-icon :icon="['fal','pepper-hot']"/>
            </v-col>
          </v-row>
        </v-card>
      </v-list-item-action>
    </v-list-item>
    <v-list-item @click="selectItem" v-if="item.isPriceChanged" class="px-0"
                 :style="{minHeight:'20px','background-color':( this.$vuetify.theme.dark? '' : (item === pos.gastro.selectedOpenItem ? 'rgba(63,106,216,0.45)' : (itemIndex % 2 === 1 ? '#ededed' : '')))}">
      <v-list-item-action class="pa-0 ma-0" style="width: 100% !important;">
        <v-row no-gutters>
          <v-col cols="12" align="end">
            <v-card dark elevation="0" class="pa-0 px-2 text-left rounded-br-xl"
                    style="font-size: 0.8em !important;width: fit-content;" color="error">
              {{ this.$t('generic.lang_priceChangedFrom') }}: {{ item.originalSellPrice|currency }}
            </v-card>
          </v-col>
        </v-row>
      </v-list-item-action>
    </v-list-item>
    <template
        v-if="canShowExtras">
      <v-list-item class="pa-0"
                   :style="{minHeight:'30px', 'background-color':(this.$vuetify.theme.dark? '' : (item === pos.gastro.selectedOpenItem ? 'rgba(63,106,216,0.45)' : (itemIndex % 2 === 1 ? '#ededed' : '')))}"
      >
        <v-list-item-content class="pa-0">
          <v-row justify="start" align-self="start" class="ma-0 mx-0" no-gutters>
            <v-col cols="12">
              <div v-if="true">
                <div class="text-wrap" style="font-size: 12px " v-for="(extra,i) in item.selectedExtras"
                     :key="extra.name+i">
                  &#9656; {{ extra.amount }}x {{ extra.name }} {{ extra.sellPrice | currency }}
                </div>
              </div>
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>
    </template>
    <div style="height: 1px; border-top: 1px dashed #b4b4b4; "></div>
    <ItemEditExtraDialog
        v-if="item !== null&&showExtrasDialog" @closeDialog="showExtrasDialog=false"
        :item="item" :item-group="itemGroup" :meal-size="mealSize"
        :show-dialog="showExtrasDialog" @selectExtras="selectExtras"
    />
    <ButtonsCourseDialog :pos-type="posType" :show-dialog="coursesDialog" @closeDialog="coursesDialog = false"
                         :item-course="(item.course)?item.course:null"/>
    <ButtonsFreetextDialog @closeDialog="freeTextDialog = false" :pos-type="posType" :item="item"
                           :show-dialog="freeTextDialog"/>
  </div>
</template>

<script>
import {createNamespacedHelpers, mapState} from "vuex";
import ItemEditExtraDialog from "../item/ItemEditExtraDialog";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome"
import {library} from '@fortawesome/fontawesome-svg-core';
import {roundNumber} from "@/plugins/round";
import {format} from 'mathjs';
import {
  faBarcode,
  faCheeseburger,
  faCocktail,
  faCommentAltLines,
  faConciergeBell,
  faCroissant,
  faFish,
  faFrenchFries,
  faGifts,
  faHamburger,
  faHotdog,
  faMeat,
  faPepperHot,
  faPersonCarry,
  faPizzaSlice,
  faSalad,
  faSandwich,
  faShishKebab,
  faSoup,
  faTaco,
  faUtensilsAlt,
} from '@fortawesome/pro-light-svg-icons'
import ButtonsFreetextDialog from "@/components/pos/buttons/ButtonsFreetextDialog";
import ButtonsCourseDialog from "@/components/pos/buttons/ButtonsCourseDialog";
import {Events} from "@/plugins/events";

library.add(
    faHotdog,
    faBarcode,
    faCocktail,
    faPersonCarry,
    faMeat,
    faGifts,
    faConciergeBell,
    faHamburger,
    faCheeseburger,
    faFrenchFries,
    faPizzaSlice,
    faShishKebab,
    faFish,
    faCroissant,
    faSalad,
    faTaco,
    faSandwich,
    faSoup,
    faUtensilsAlt,
    faPepperHot,
    faCommentAltLines,
);

export default {
  name: "InvoiceOpenItem",

  components: {ButtonsCourseDialog, ButtonsFreetextDialog, ItemEditExtraDialog, 'font-awesome-icon': FontAwesomeIcon},

  props: {
    item: Object,
    itemIndex: Number
  },

  data() {
    return {
      showExtras: false,
      showExtrasDialog: false,
      freeTextDialog: false,
      coursesDialog: false,
      baseItem: null,
      itemGroup: null
    }
  },
  async mounted() {
    if(this.item && !this.item.ean.includes("EVTB$") &&  !this.item.ean.includes("V$") && !this.item.ean.includes("GF$") && !this.item.ean.includes("ZMYLE$") && !this.item.ean.includes("MYWORLD$") && !this.item.randomPositionID.includes("$deliveryCost_")){
      this.baseItem = await this.$store.dispatch("items/getItemByID", this.item.id);
      this.itemGroup = await this.$store.dispatch("itemgroups/getItemgroupByID", this.item.itemgroupID);
    }
  },

  watch: {
    selectedItem(val) {
      if (val && this.item.id === val.id) {
        Events.$emit("itemSelected", 'open_' + val.randomPositionID);
      }
    },
  },
  computed: {
    ...mapState([
      'pos',
      'mealsizes',
      'deposit',
      'extras'
    ]),
    ...createNamespacedHelpers('pos/retail').mapGetters([
      "selectedItem"
    ]),
    showGFMin() {
      return this.item?.ean?.includes("GF$") && this.item?.hasOwnProperty('min_redeem_cart_value') && this.item.min_redeem_cart_value > 0;
    },
    canShowExtras(){
      return this.selectedItem !== null && this.item.selectedExtras?.length > 0 && this.selectedItem.randomPositionID === this.item.randomPositionID;
    },
    posType() {
      let posType = this.$route.name.split('.');
      return posType[posType.length - 1];
    },
    selectedItem() {
      if (this.posType === 'retail') {
        return this.$store.getters['pos/retail/selectedItem'];
      } else if (this.posType === 'wholesaleRetail') {
        return this.$store.getters['pos/wholesaleRetail/selectedItem'];
      } else {
        return this.$store.getters['pos/gastro/selectedOpenItem'];
      }
    },
    mealSize() {
      if (this.baseItem === null)
        return null;

      if (!this.baseItem)
        return null;

      let mealSize = this.baseItem.mealSizes.find(size => this.item.mealSizeID === size.id);

      if (!mealSize)
        return null;

      return mealSize;
    },
    depositGroup() {
      if (this.item.depositgroupID === 0)
        return null;

      //FIND DEPOSIT GROUP
      let depositGroup = this.deposit.deposits.find((depositGroup) => {
        return depositGroup.id === this.item.depositgroupID;
      });

      if (!depositGroup)
        return null;

      return depositGroup;
    },
    itemName() {
      //CHECK IF ITEM HAS MEALSIZE ID
      if (this.item.mealSizeID > 0) {
        //FIND MEALSIZE
        let mealsize = this.mealsizes.mealSizes.find((mealsize) => mealsize.id === this.item.mealSizeID);

        if (mealsize)
          return this.item.name + " " + mealsize.name;
      }

      return this.item.name;
    },
    itemSellPrice() {
      let sellPrice = this.item.sellPrice;

      // -----------------------------------------------------
      // --------------------- DISCOUNT -----------------------
      //Discount ONLY IN %
      if (this.item.sellPrice === this.item.originalSellPrice && this.item.discount > 0) {
        //sellPrice = ((100 - this.item.discount) / 100) * sellPrice;
        //sellPrice =  parseFloat((Math.round(((sellPrice * ((100 - this.item.discount) / 100)) + Number.EPSILON) * 100) / 100).toFixed(2));
        sellPrice = parseFloat(((((sellPrice * ((100 - this.item.discount) / 100)) + Number.EPSILON) * 100) / 100));
        sellPrice = format(sellPrice, {precision: 14});
        sellPrice = roundNumber(sellPrice, 2);
      }

      //DISCOUNT WITH PRICE CHANGE
      if (this.item.sellPrice !== this.item.originalSellPrice && this.item.discount === 0) {
        sellPrice = 1 * sellPrice;
      }

      //DISCOUNT IN % AND PRICECHANGE
      if (this.item.sellPrice !== this.item.originalSellPrice && this.item.discount > 0) {
        //sellPrice = 1 * (((100 - this.item.discount) / 100) * sellPrice)
        //sellPrice =  parseFloat((Math.round(((sellPrice * ((100 - this.item.discount) / 100)) + Number.EPSILON) * 100) / 100).toFixed(2));
        sellPrice = parseFloat(((((sellPrice * ((100 - this.item.discount) / 100)) + Number.EPSILON) * 100) / 100));
        sellPrice = format(sellPrice, {precision: 14});
        sellPrice = roundNumber(sellPrice, 2);
      }
      // -----------------------------------------------------

      //CHECK DEPOSIT
      if (this.depositGroup !== null) {
        sellPrice += this.depositGroup.priceTotal;
      }

      sellPrice = format(sellPrice, { precision: 14 });
      sellPrice = roundNumber(sellPrice, 2);
      return sellPrice;
    },
    itemStackSellPrice() {
      let weightCalc = 0.00;
      weightCalc = this.itemSellPrice * this.item.amount
      weightCalc = format(weightCalc, { precision: 14 });
      weightCalc = roundNumber(weightCalc, 2);
      return weightCalc;
    },
    itemHasExtras() {
      if (this.baseItem === null || this.baseItem === undefined)
        return false;

      if (this.item && this.item.randomPositionID.includes('$deliveryCost'))
        return false;

      let availableExtras = [];

      if (this.posType === "gastro" && this.itemGroup && this.itemGroup.extras) {
        if (Object.keys(this.itemGroup.extras).length > 0 && this.itemGroup.extras.constructor === Object) {
          availableExtras = this.itemGroup.extras;
        }
      }
      //console.log(this.baseItem);
      if (this.posType === "gastro" && this.baseItem.extras) {
        if (Object.keys(this.baseItem.extras).length > 0 && this.baseItem.extras.constructor === Object) {
          availableExtras = this.baseItem.extras;
        }
      }

      if (this.posType === "gastro" && (Object.keys(availableExtras).length > 0 && availableExtras.constructor === Object) && !this.selectedExtras) {
        //CHECK

        let counter = 0;

        //LOOP GARNISHTABS
        for (let tabID in availableExtras) {
          if (!availableExtras.hasOwnProperty(tabID)) {
            return false;
          }

          //LOOP GARNISH ITEMS
          for (let garnishID in availableExtras[tabID]) {
            if (!availableExtras[tabID].hasOwnProperty(garnishID)) {
              return false;
            }
            let extra = Object.assign({}, this.extras.extras.find(item => item.id === parseInt(garnishID)));

            if (Object.keys(extra).length < 1) {

              break;
            }
            //LOOP GARNISH SIZES
            for (let garnishSizeID in availableExtras[tabID][garnishID]) {
              if (!availableExtras[tabID][garnishID].hasOwnProperty(garnishSizeID)) {
                return false;
              }
              if (parseInt(availableExtras[tabID][garnishID][garnishSizeID][1]) < 1) {

                break;
              }

              //CHECK
              if (this.mealSize !== null) {
                if (this.mealSize.id > 0 && parseInt(garnishSizeID) === this.mealSize.id) {
                  counter++;
                } else if (this.mealSize.id === 0 && parseInt(garnishSizeID) === 0) {
                  counter++;
                }
              } else {
                if (parseInt(garnishSizeID) === 0) {
                  counter++;
                }
              }
            }
          }
        }

        if (counter > 0) {
          return true
        }
      }
      return false;
    }
  },

  methods: {
    selectItem() {
      if (this.pos.gastro.isDeliveryService) {
        if (this.item && !this.item.randomPositionID.includes('$deliveryCost')) {
          this.$store.dispatch("pos/gastro/selectInvoiceOpenItem", this.item);
        }
      } else {
        this.$store.dispatch("pos/gastro/selectInvoiceOpenItem", this.item);
      }
    },
    toggleFreeText(){
      if(this.selectedItem?.randomPositionID !== this.item.randomPositionID)
        this.selectItem();
      
      this.freeTextDialog = true;
    },
    selectExtras(extras) {
      let newPrice = this.item.originalSellPrice;

      //CHECK IF WE HAVE MEALSIZE
      if (this.mealSize !== null)
        newPrice = this.mealSize.price;

      extras.forEach((extra) => {
        newPrice += (parseInt(extra.amount) * parseFloat(extra.sellPrice));
      });

      this.$store.dispatch("pos/gastro/setItemExtras", {
        index: this.itemIndex,
        extras: extras,
        newPrice: newPrice,
      }).then(() => {
        this.showExtrasDialog = false;
      });
    },
    openEditExtrasDialog() {
      this.showExtrasDialog = true;
    },
    getCourseIcon() {
      if (this.item.course.courseIcon === null || this.item.course.courseIcon === '') {
        return 'utensils-alt';
      } else {
        return this.item.course.courseIcon;
      }
    },
  }
}
</script>
